* {
  font-family: inter;
  box-sizing: border-box;

}

.sidebar {
  position: absolute;
  width: 25vw;
  padding: 3vh;
}

.todoListContainer {
  position: absolute;
  width: 75vw;
  left: 25vw;
  top: 0px;
  background: #E9E9E9;
  padding: 3vh;
  min-height: 100%;
}

.todoContainer {
  position: relative;
  width: 50vw;
  height: 8vh;
  left: 4vw;
  bottom: 1vw;
  background: #fff;
  cursor: pointer;
  border-radius: 0.4vw;
}


.todoTitle {
  font-size: x-large;
  padding: 2vh 3vh;
}

.todoListTitle {
  margin-bottom: 7vh;
}

.deleteTodo {
  position: relative;
  float: right;
}



input[type="text"] {
  /* outline: none;
  color: rgb(55 65 81);*/
  line-height: 1.25;
  border: none;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: rgb(229 231 235);
  border-color: rgb(229 231 235);
  border-width: 2px;
  border-radius: 0.25rem;
  appearance: none;
  width: 100%;
  font-size: 100%;
  margin-bottom: 1vh;

}


select {
  /* color: rgb(55 65 81); */
  cursor: pointer;
  line-height: 1.25;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: rgb(229 231 235);
  border-color: rgb(229 231 235);
  border-width: 2px;
  border-radius: 0.25rem;
  appearance: none;
  width: 100%;
  font-size: 100%;
}

button {
  color: rgb(255 255 255);
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: rgb(79 70 229);
  border-color: transparent;
  border-width: 1px;
  border-radius: 0.375rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  cursor: pointer;
  text-transform: none;
  margin-top: 1.5rem;
}